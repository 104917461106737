import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

const GridLayout = ({
  items,
  context,
  itemComponent: ItemComponent,
  itemContext: Context,
  namespace,
  preload,
  deviceType,
  countryConfig,
  preloadNumImage,
}) => (
  <div className={classNames(`${namespace}__grid`)}>
    <Context
      contextValue={{ ...context, title_tag: 'h2' }}
      deviceType={deviceType}
      countPreloadImages={preloadNumImage}
      thousandSeparator={countryConfig?.thousands_separator}
      decimalSeparator={countryConfig?.decimal_separator}
    >
      {items.map((item, index) => (
        <ItemComponent
          item={item}
          preload={preload && index < 3}
          key={item?.metadata?.id || item.id}
          className={classNames(`${namespace}__item`)}
          index={index + 1}
        />
      ))}
    </Context>
  </div>
);

GridLayout.propTypes = {
  context: PropTypes.shape({}),
  countryConfig: PropTypes.shape({
    decimal_separator: PropTypes.string,
    thousands_separator: PropTypes.string,
  }),
  deviceType: PropTypes.string,
  itemComponent: PropTypes.elementType,
  itemContext: PropTypes.elementType,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  namespace: PropTypes.string,
  preload: PropTypes.bool,
  preloadNumImage: PropTypes.number,
};

GridLayout.defaultProps = {
  context: {},
  itemContext: ({ children }) => children,
};

export default GridLayout;
